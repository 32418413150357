import React, { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { LearningHeader } from '@skillfactory/frontend-component-header';
import Footer from '@skillfactory/frontend-component-footer';
import {
  Loader, useAppStore, useScheme,
  useTheme,
} from '@skillfactory/frontend-theme';

import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '@edx/frontend-platform/react';

import { Redirect, useParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getLoginRedirectUrl } from '@edx/frontend-platform/auth';
import { getConfig } from '@edx/frontend-platform';
import { useFeedbackWidget } from '../../utils/use-feedback-widget';
import fetchDeadlines from '../../deadlines/data/thunks';
import { useModel } from '../../generic/model-store';
import { fetchOutlineTab } from '../../course-home/data';

const SkillFactoryPage = ({
  isLoading = false,
  headerVariant,
  isHeaderDisplayed = true,
  isFooterDisplayed = true,
  isSlackLinkDisplayed = false,
  isChatLinkDisplayed,
  additionalHeaderLinks = [],
  isCourseSpecificTabsShownInHeader = false,
  homeworkPanelVisible,
  children,
  className,
}) => {
  const dispatch = useDispatch();

  const {
    courseId,
  } = useSelector(state => state.courseHome);

  const { courseId: courseIdFromUrl } = useParams();

  useEffect(() => {
    // The courseId from the URL is the course we WANT to load.
    if (isCourseSpecificTabsShownInHeader) { dispatch(fetchOutlineTab(courseIdFromUrl)); }
  }, [courseIdFromUrl]);

  useFeedbackWidget(courseIdFromUrl);

  const scheme = useScheme(state => state.scheme);
  const theme = useTheme(state => state.theme);

  const { tabs } = useModel('courseHomeMeta', courseId);
  const headerTabLinks = useMemo(() => {
    if (!tabs) { return []; }
    return tabs
      .filter(tab => tab.slug !== 'progress' && tab.slug !== 'outline')
      .map(tab => ({
        label: tab.title,
        href: tab.url,
        isVisible: tab.title === 'FAQ' || tab.title === 'Поддержка',
      }));
  }, [tabs]);

  const additionalLinks = useMemo(() => (!isCourseSpecificTabsShownInHeader
    ? additionalHeaderLinks
    : [...additionalHeaderLinks, ...headerTabLinks]),
  [isCourseSpecificTabsShownInHeader, additionalHeaderLinks, headerTabLinks]);

  return (
    <div className={classNames('sf-page', className)}>
      {isHeaderDisplayed && (
      <LearningHeader
        className="sf-page__header"
        variant={headerVariant}
        additionalLinks={additionalLinks}
        scheme={scheme}
        theme={theme}
        logo={window.location.hostname.includes(getConfig().CONTENTED_HOST)
          ? 'contented'
          : 'skillfactory'}
        isContented={window.location.hostname.includes(getConfig().CONTENTED_HOST)}
      />
      )}
      <main className="sf-page__main">
        {isLoading
          ? <Loader className="sf-page__loader" />
          : (
            <div className="sf-page__content">
              {children}
            </div>
          )}
        {/* {!isLoading && ( */}
        {/* // <div className="sf-page__sticky-navigation-wrapper"> */}
        {/* //   <div className="sf-page__sticky-navigation"> */}
        {/* //     /!* {homeworkPanelProps && ( *!/ */}
        {/* //     /!*  <HomeworkPanel *!/ */}
        {/* //     /!*    dateUntil={new Date()} *!/ */}
        {/* //     /!*    className="sf-page__homework-panel" *!/ */}
        {/* //     /!*    {...homeworkPanelProps} *!/ */}
        {/* //     /!*  /> *!/ */}
        {/* //     /!* )} *!/ */}
        {/* // */}
        {/* //     /!*<div className="sf-page__right">*!/ */}
        {/* //       /!* {isSlackLinkDisplayed && <SlopedIconButton *!/ */}
        {/* //       /!*    className="sf-page__slack-link" *!/ */}
        {/* //       /!*    as={<a/>} *!/ */}
        {/* //       /!*    icon={<SlackIcon/>} *!/ */}
        {/* //       /!*    onClick={() => { *!/ */}
        {/* //       /!*    }} *!/ */}
        {/* //       /!* />} *!/ */}
        {/* // */}
        {/* //       /!*<ChatIconButton className="sf-page__chat-icon"
          tooltipClassName="sf-page__chat-icon-tooltip" />*!/ */}
        {/* //     /!*</div>*!/ */}
        {/* //   </div> */}
        {/* // </div> */}
        {/* )} */}
      </main>

      {isFooterDisplayed && <Footer className={classNames({ 'sf-footer--rounded': isLoading })} scheme={window.location.href.includes(getConfig().CONTENTED_HOST) ? 'contented' : 'skillfactory'} />}
    </div>
  );
};

export default SkillFactoryPage;
