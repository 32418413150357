import 'core-js/stable';
import 'regenerator-runtime/runtime';

import {
  APP_INIT_ERROR, APP_READY, subscribe, initialize,
  mergeConfig, getConfig,
} from '@edx/frontend-platform';
import {
  AppContext, AppProvider, ErrorPage, PageRoute,
} from '@edx/frontend-platform/react';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { Switch } from 'react-router-dom';

import { messages as footerMessages } from '@skillfactory/frontend-component-footer';
import { enableMapSet } from 'immer';

import {
  AxiosJwtAuthService,
} from '@edx/frontend-platform/auth';
import { ThemeProvider } from '@skillfactory/frontend-theme';
import { Redirect } from 'react-router';
import { configTora } from '@skillfactory/tora-frontend';
import appMessages from './i18n';
import { UserMessagesProvider } from './generic/user-messages';
import { CourseExit } from './courseware/course/course-exit';
import CoursewareContainer from './courseware';
import CoursewareRedirectLandingPage from './courseware/CoursewareRedirectLandingPage';
import DatesTab from './course-home/dates-tab';
import GoalUnsubscribe from './course-home/goal-unsubscribe';
import Dashboard from './dashboard';
import { TabContainer } from './tab-page';

import { fetchDatesTab } from './course-home/data';
import { fetchCourse } from './courseware/data';
import initializeStore from './store';
import NoticesProvider from './generic/notices';
import Outline from './course-home/outline/Outline';
import CourseHomePage from './course-home/CourseHomePage';
import LoginPage from './auth/pages/login/LoginPage';
import RegisterPage from './auth/pages/register/RegisterPage';
import ResetPasswordPage from './auth/pages/reset-password/ResetPasswordPage';
import ResetPasswordSuccessPage from './auth/pages/reset-password-success/ResetPasswordSuccessPage';
import RegisterSuccessPage from './auth/pages/register-success/RegisterSuccessPage';
import ProgressTabLoader from './course-home/progress-tab/ProgressTabLoader';
import ToraPage from './components/tora-page/ToraPage';
import Forbidden from './forbidden';
import ConnectAccountPage from './auth/pages/connect-account/ConnectAccountPage';
import TelegramBindingPage from './auth/pages/telegram-binding/TelegramBindingPage';

import './index.scss';

const App = () => {
  const { authenticatedUser } = useContext(AppContext);

  return (
    <Switch>
      <PageRoute path="/telegram-binding">
        <TelegramBindingPage />
      </PageRoute>
      <PageRoute path="/login">
        <LoginPage />
      </PageRoute>
      <PageRoute path="/account-join">
        <ConnectAccountPage />
      </PageRoute>
      <PageRoute path="/register">
        <RegisterPage />
      </PageRoute>
      <PageRoute path="/password-reset">
        <ResetPasswordPage />
      </PageRoute>
      <PageRoute path="/password-reset-success">
        <ResetPasswordSuccessPage />
      </PageRoute>
      <PageRoute path="/register-success">
        <RegisterSuccessPage />
      </PageRoute>
      <PageRoute exact path="/forbidden" component={Forbidden} />
      <PageRoute path="/">
        {!authenticatedUser ? <Redirect to={`/login?next=${encodeURIComponent(window.location.href)}`} /> : (
          <Switch>
            <PageRoute exact path="/dashboard" component={Dashboard} />
            <PageRoute exact path="/goal-unsubscribe/:token" component={GoalUnsubscribe} />
            <PageRoute path="/redirect" component={CoursewareRedirectLandingPage} />
            <PageRoute
              path={['/course/:courseId/home',
                '/course/:courseId/progress',
                '/course/:courseId/progress/:targetUserId/',
              ]}
              render={({ match }) => {
                const { path, params } = match;
                return (
                  <CourseHomePage targetUserId={match.params.targetUserId} courseId={params.courseId}>
                    {path === '/course/:courseId/home' ? <Outline /> : <ProgressTabLoader />}
                    {/* <Outline /> */}
                  </CourseHomePage>
                );
              }}
            />
            <PageRoute path="/course/:courseId/dates">
              <TabContainer tab="dates" fetch={fetchDatesTab} slice="courseHome">
                <DatesTab />
              </TabContainer>
            </PageRoute>
            <PageRoute path="/course/:courseId/course-end">
              <TabContainer tab="courseware" fetch={fetchCourse} slice="courseware">
                <CourseExit />
              </TabContainer>
            </PageRoute>
            <PageRoute
              path={[
                '/course/:courseId/:sequenceId/:unitId',
                '/course/:courseId/:sequenceId',
                '/course/:courseId',
              ]}
              component={CoursewareContainer}
            />
            <PageRoute
              exact
              path={`${getConfig().TORA_BLOCK_URL}/:id`}
              component={ToraPage}
            />
          </Switch>
        )}
      </PageRoute>
    </Switch>
  );
};

enableMapSet();

subscribe(APP_READY, () => {
  enableMapSet();

  configTora(getConfig());
  ReactDOM.render(
    <AppProvider store={initializeStore()}>
      <ThemeProvider
        initStyle={getConfig().CONTENTED_THEME_AND_SCHEME_ENABLED}
        contentedHost={getConfig().CONTENTED_HOST}
      >
        <NoticesProvider>
          <UserMessagesProvider>
            <App />
          </UserMessagesProvider>
        </NoticesProvider>
      </ThemeProvider>
    </AppProvider>,
    document.getElementById('root'),
  );
});

subscribe(APP_INIT_ERROR, (error) => {
  ReactDOM.render(<ErrorPage message={error.message} />, document.getElementById('root'));
});

const forbiddenInterceptor = error => {
  if (error.response.status === 403) {
    window.location.href = `/forbidden?attempted=${encodeURIComponent(window.location.href)}`;
  }
  return Promise.reject(error);
};

class AuthService extends AxiosJwtAuthService {
  constructor(options) {
    super(options);
    this.authenticatedHttpClient.interceptors.response.use(response => response, forbiddenInterceptor);
    this.httpClient.interceptors.response.use(response => response, forbiddenInterceptor);
    // eslint-disable-next-line no-unused-expressions,max-len
    this.cachedAuthenticatedHttpClient && this.cachedAuthenticatedHttpClient.interceptors.response.use(response => response, forbiddenInterceptor);
    // eslint-disable-next-line no-unused-expressions,max-len
    this.cachedHttpClient && this.cachedHttpClient.interceptors.response.use(response => response, forbiddenInterceptor);
  }

  async fetchAuthenticatedUser(options = {}) {
    const decodedAccessToken = await this.jwtTokenService.getJwtToken(options.forceRefresh || false);

    if (decodedAccessToken !== null) {
      this.setAuthenticatedUser({
        email: decodedAccessToken.email,
        userId: decodedAccessToken.user_id,
        username: decodedAccessToken.preferred_username,
        roles: decodedAccessToken.roles || [],
        administrator: decodedAccessToken.administrator,
        name: decodedAccessToken.name,
      });
    } else {
      this.setAuthenticatedUser(null);
    }

    return this.getAuthenticatedUser();
  }
}

initialize({
  authService: AuthService,
  handlers: {
    config: () => {
      mergeConfig({
        CONTACT_URL: process.env.CONTACT_URL || null,
        CREDENTIALS_BASE_URL: process.env.CREDENTIALS_BASE_URL || null,
        ENTERPRISE_LEARNER_PORTAL_HOSTNAME: process.env.ENTERPRISE_LEARNER_PORTAL_HOSTNAME || null,
        ENABLE_JUMPNAV: process.env.ENABLE_JUMPNAV || null,
        ENABLE_NOTICES: process.env.ENABLE_NOTICES || null,
        INSIGHTS_BASE_URL: process.env.INSIGHTS_BASE_URL || null,
        SEARCH_CATALOG_URL: process.env.SEARCH_CATALOG_URL || null,
        SOCIAL_UTM_MILESTONE_CAMPAIGN: process.env.SOCIAL_UTM_MILESTONE_CAMPAIGN || null,
        STUDIO_BASE_URL: process.env.STUDIO_BASE_URL || null,
        SUPPORT_URL: process.env.SUPPORT_URL || null,
        SUPPORT_URL_CALCULATOR_MATH: process.env.SUPPORT_URL_CALCULATOR_MATH || null,
        SUPPORT_URL_ID_VERIFICATION: process.env.SUPPORT_URL_ID_VERIFICATION || null,
        SUPPORT_URL_VERIFIED_CERTIFICATE: process.env.SUPPORT_URL_VERIFIED_CERTIFICATE || null,
        TERMS_OF_SERVICE_URL: process.env.TERMS_OF_SERVICE_URL || null,
        TWITTER_HASHTAG: process.env.TWITTER_HASHTAG || null,
        LEGACY_THEME_NAME: process.env.LEGACY_THEME_NAME || null,
        DASHBOARD_PAGE_PATHNAME: process.env.DASHBOARD_PAGE_PATHNAME || null,
        OAUTH_COMPLETE_URL: process.env.OAUTH_COMPLETE_URL || null,
        CAREER_CENTER: process.env.CAREER_CENTER || null,
        PROJECTS_FACTORY: process.env.PROJECTS_FACTORY || null,
        PRIVACY_POLICY_URL: process.env.PRIVACY_POLICY_URL || null,
        USER_AGREEMENT_URL: process.env.USER_AGREEMENT_URL || null,
        SUPPORT_URL_SF: process.env.SUPPORT_URL_SF || null,
        SUPPORT_URL_CD: process.env.SUPPORT_URL_CD || null,
        DZEN_URL: process.env.DZEN_URL || null,
        HABR_URL: process.env.HABR_URL || null,
        TELEGRAM_URL: process.env.TELEGRAM_URL || null,
        SF_URL: process.env.SF_URL || null,
        TWITTER_URL: process.env.TWITTER_URL || null,
        VK_URL: process.env.VK_URL || null,
        MAX_URL: process.env.MAX_URL || null,
        TUNE_IT_URL: process.env.TUNE_IT_URL || null,
        CONTENTED_HOST: process.env.CONTENTED_HOST || null,
        CONTENTED_THEME_AND_SCHEME_ENABLED: process.env.CONTENTED_THEME_AND_SCHEME_ENABLED === 'true',
        SSO_LOGIN_URL: process.env.SSO_LOGIN_URL || null,
        SKILLFACTORY_SUPPORT_URL: process.env.SKILLFACTORY_SUPPORT_URL || null,
        CONTENTED_SUPPORT_URL: process.env.CONTENTED_SUPPORT_URL || null,
        TORA_HOST: process.env.TORA_HOST || null,
        TORA_FILES_BASE_URL: process.env.TORA_FILES_BASE_URL || null,
        TORA_GRADING_BASE_URL: process.env.TORA_GRADING_BASE_URL || null,
        TORA_TASK_BASE_URL: process.env.TORA_TASK_BASE_URL || null,
        TORA_GATEWAY_BASE_URL: process.env.TORA_GATEWAY_BASE_URL || null,
        TORA_ATTACHMENTS_URL: process.env.TORA_ATTACHMENTS_URL || null,
        TORA_BLOCK_URL: process.env.TORA_BLOCK_URL || null,
        MENTOR_PROFILE_URL: process.env.MENTOR_PROFILE_URL || null,
        STAFF_PROFILE_URL: process.env.STAFF_PROFILE_URL || null,
        STUDENT_PROFILE_URL: process.env.STUDENT_PROFILE_URL || null,
        STUDENT_PROFILE_CONTENTED_URL: process.env.STUDENT_PROFILE_CONTENTED_URL || null,
        REFERRAL_SKILLFACTORY_URL: process.env.REFERRAL_SKILLFACTORY_URL || null,
        REFERRAL_CONTENTED_URL: process.env.REFERRAL_CONTENTED_URL || null,
        IS_REFERRAL_PROGRAM_ENABLED: process.env.IS_REFERRAL_PROGRAM_ENABLED || null,
      }, 'LearnerAppConfig');
    },
  },
  messages: [
    appMessages,
    footerMessages,
  ],
});
